import type { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { MENUS_SHOWCASE_APP_DEF_ID, PAGE_DATA } from 'root/utils/consts';
import type { ComponentStructure } from 'root/utils/OOI/consts';
import { WIDGET_IDS, widgetLayout } from 'root/utils/OOI/consts';
import { setPagesState } from 'root/utils/OOI/editorScript/shared/setPagesState';
import { createWidgetDefinition } from 'root/utils/OOI/editorScript/shared/createDefinitions';

export const migrateByRef = async ({
  editorSDK,
  tpaAppId,
  ref,
  isResponsive,
}: {
  editorSDK: EditorSDK;
  tpaAppId: number;
  ref: ComponentRef;
  isResponsive: boolean;
}) => {
  await editorSDK.document.transactions.runAndWaitForApproval('token', async () => {
    const ancestors = await editorSDK.document.components.getAncestors('', { componentRef: ref });
    const parent = ancestors[0];

    const tpaWidget: ComponentStructure = isResponsive
      ? createWidgetDefinition({
          appDefinitionId: MENUS_SHOWCASE_APP_DEF_ID,
          widgetId: WIDGET_IDS.menus,
          tpaAppId,
          overrides: {
            parent: parent.id,
          },
        })
      : {
          componentType: 'wysiwyg.viewer.components.tpapps.TPASection',
          data: {
            applicationId: `${tpaAppId}`,
            widgetId: WIDGET_IDS.menus,
            appDefinitionId: MENUS_SHOWCASE_APP_DEF_ID,
            type: 'TPA',
          },
          parent: parent.id,
          skin: 'wysiwyg.viewer.skins.TPASectionSkin',
          layout: widgetLayout,
          layouts: undefined,
        };

    await editorSDK.document.components.remove('', { componentRef: ref });
    await editorSDK.document.components.addAndAdjustLayout('', {
      componentDefinition: tpaWidget,
      pageRef: parent,
    });

    await setPagesState({ editorSDK });
  });
};

export const migrateAll = async ({
  editorSDK,
  tpaAppId,
  isResponsive,
}: {
  editorSDK: EditorSDK;
  tpaAppId: number;
  isResponsive: boolean;
}) => {
  const allRefComponents = await editorSDK.document.components.refComponents.getAllAppRefComponents('');
  const components: { ref: ComponentRef; data: object }[] = await Promise.all(
    allRefComponents.map(async (ref) => {
      return { ref, data: await editorSDK.document.components.data.get('', { componentRef: ref }) };
    })
  );

  // @ts-expect-error
  const blocksComponents = components.filter((component) => component.data.widgetId === PAGE_DATA.widgetId);

  await Promise.all(
    blocksComponents.map((component) => migrateByRef({ editorSDK, tpaAppId, ref: component.ref, isResponsive }))
  );
};
